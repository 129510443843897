<template>
  <div class="row justify-content-center" style="min-height: calc(100vh - 180px);">
    <div class="card col-md-10 p-0">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="closeButton"
        />
      </div>
      <form @submit.prevent="saveOPDBilling">
        <div class="px-3 py-1">
          <div class="row">
            <div class="col-md-4">
              <label class="form-label">Consultant</label>
              <v-select placeholder="Select consultant"
                v-model="formData.service_resource_id"
                :options="serviceResources"
                :reduce="name => name.id"
                label="name"
                @option:selected="onSelectServiceResource"
              />
            </div>
            <div class="col-md-4">
              <label class="form-label">Business Unit</label>
              <v-select
                  placeholder="Select Business"
                  v-model="formData.business_id"
                  :options="business"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label">Service Serial No</label>
              <vField
                  name="service_serial_no"
                  type="text"
                  class="form-control"
                  v-model="formData.service_serial_no"
                  readonly=""
              />
            </div>
          </div>

          <div class="d-flex flex-wrap gap-1 gy-10 mbt-20">
            <div class="col">
              <input
                  v-model="formData.date"
                  name="datetime"
                  type="datetime-local"
                  class="form-control"
              />
            </div>
            <div class="col">
              <input
                  @change="onSelectServiceResource"
                  v-model="formData.invoice_date"
                  name="datetime"
                  type="datetime-local"
                  class="form-control"
                  disabled="disabled"
              />
            </div>
            <div class="col form-check d-flex align-items-center">
              <input class="form-check-input mr-3" type="checkbox">
              <label class="form-check-label" for="sendSms">Send SMS</label>

            </div>
          </div>

          <div class="row gap-1">
            <div class="col-md-8 mb-10">
              <AsyncSelect
                placeholder="Patient Name, ID, Mobile No"
                v-model="selectedPatient"
                :api-service="fetchContactProfiles"
                :additional-query="additionalQuery"
                :format-label="formatPatientLabel"
                :additional-option="additionalOption"
              />
            </div>
            <div class="col-md-3">
              <button
                  type="button"
                  class="btn btn-primary"
                  @click="openPatientAddModal"
              >
                New Patient
              </button>
            </div>

          </div>

          <div class="row py-1 justify-content-between">
            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="fullName">Full Name</label>
                <vField
                    name="full_name"
                    type="text"
                    class="form-control"
                    v-model="patient.full_name"
                    readonly=""
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="patientId">Patient ID</label>
                <vField
                    name="patient_id"
                    type="text"
                    class="form-control"
                    v-model="patient.serial_no"
                    readonly=""
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label" for="mobileNo">Mobile No</label>
                <vField
                    name="mobile"
                    type="text"
                    class="form-control"
                    v-model="patient.mobile_no"
                    readonly=""
                />
              </div>
              <div class="d-flex gap-1">
                <div class="mb-1 col-md-5">
                  <label class="form-label" for="age">Age</label>
                  <vField
                      name="birthday"
                      type="text"
                      class="form-control"
                      v-model="patient.age"
                      readonly=""
                  />
                </div>
                <div class="mb-1 col-6">
                  <label class="form-label" for="gender">Gender</label>
                  <vField
                      name="gender"
                      type="text"
                      class="capitalize form-control"
                      v-model="patient.gender"
                      readonly=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="background: #DBE7F2" class="p-2">
          <TitleButton
              btnTitle="Add New"
              :showBtn="false"
              :showSettingBtn="false"
              :showAddNew="false"
              :title="'Pay Now'"
          />
        </div>

        <div class="px-3 py-1">
          <div class="row py-1 justify-content-between">
            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label">Invoice No</label>
                <div class="d-flex align-items-center justify-content-md-end mb-1">
                  <div class="input-group input-group-merge invoice-edit-input-group">
                    <div class="input-group-text">
                      <span>{{ invoicePrefix }}-</span>
                    </div>
                    <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                           v-model="invoiceSerial">
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="visitType">Visit Type</label>
                <v-select
                    placeholder="Select Visit Type"
                    v-model="formData.service_specification"
                    label="name"
                    :options="specificationArr"
                    :reduce="name => name.id"
                    @option:selected="onSelectVisitType"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="feesAmount">Fees Amount</label>
                <vField
                    v-model="account_details.amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="discount">Discount / Round up adjust</label>
                <vField
                    v-model="formData.adjustment_amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="mb-1" v-if="formData.adjustment_amount > 0">
                <label class="form-label" for="discount">Discount Acount Head</label>
                <v-select
                  placeholder="Select Discount Head"
                  v-model="formData.discount_head_id"
                  :options="salesAccountHeads"
                  label="name"
                  :reduce="name => name.id"
                />
              </div>

              <div class="mb-1">
                <label class="form-label" for="netPayable">Net Payable</label>
                <vField
                    v-model="netPayable"
                    readonly=""
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-1">
                <label class="form-label">Payment Invoice No</label>
                <div class="d-flex align-items-center justify-content-md-end mb-1">
                  <div class="input-group input-group-merge invoice-edit-input-group">
                    <div class="input-group-text">
                      <span>{{ paymentPrefix }}-</span>
                    </div>
                    <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                           v-model="paymentSerial">
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="paymentMode">Mode of Payment</label>
                <v-select
                    placeholder="Select Cash & Bank Account"
                    class="w-100"
                    v-model="formData.mop_account_head_id"
                    :options="cashAndBank"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="mopReference">MoP Reference</label>
                <vField
                    v-model="formData.receipt_reference"
                    name="mop_reference"
                    type="text"
                    class="form-control"
                />
              </div>
              <div class="mb-1">
                <label class="form-label" for="receiptAmount">Receive Amount</label>
                <vField
                    v-model="formData.payment"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
              </div>
              <div class="w-100">
                <button type="submit" class="btn btn-primary w-100" :disabled="loading">Submit Return</button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>

    <AddPatientModal
        v-if="$store.state.isModalOpenTwo"
        :doctors="doctors"
        @onCreateProfile="onCreateProfile"
    />

    <Loader v-if="loading"/>

  </div>

</template>

<script setup>
import {computed, inject, onMounted, reactive, ref, watch} from 'vue';
import TitleButton from '@/components/atom/TitleButton.vue';
import {useRoute, useRouter} from "vue-router";
import handleHospital from "@/services/modules/hospital";
import Loader from "@/components/atom/LoaderComponent.vue";
import handleInventory from "@/services/modules/inventory";
import handleReceipt from "@/services/modules/receipt";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import objToArray from "@/services/utils/object-to-array";
import handleCBusinesses from "@/services/modules/businesses";
import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handlePurchase from "@/services/modules/purchase";
import handleVoucherNo from "@/services/modules/refNumber";
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import { useStore } from 'vuex';
import handleContact from '@/services/modules/contact'
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import handleCompany from "@/services/modules/company";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import salesReturnHelper from "@/services/utils/pdf/salesReturnHelper";

const { fetchContactProfiles } = handleContact()
const { generatePdf } = hospitalPdfPrinterHelper();
const { fetchAndGeneratePdf } = salesReturnHelper();
const { fetchCompanyInfo } = handleCompany();
const { getAccountHeadBySlag } = handlePurchase()
const { formatPatientLabel } = useAsyncDropdownHelper()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const $route = useRoute();
const $router = useRouter();
const $store = useStore();

let invoicePrefix = ref('')
let invoiceSerial = ref('')

let paymentPrefix = ref('')
let paymentSerial = ref('')

let headerTitle = ref('Return OPD Billing');
const loading = ref(false);
const invoiceRes = ref({})
const salesAccountHeads = ref([])
const company = ref({});
const additionalQuery = {
  type: "patient"
}

const openPatientAddModal = () => {
  $store.state.isModalOpenTwo = true
}
const closeButton = () => {
  const {redirect_route, ...routeQuery} = $route.query
  const routeName = redirect_route ? redirect_route : "bill-manager"
  $router.push({
    name: routeName,
    params: $route.params,
    query: {
        ...routeQuery
    }
  })
}

const getCurrentDateTime = () => {
  const offset = new Date().getTimezoneOffset();
  const localDate = new Date(Date.now() - offset * 60000);
  return localDate.toISOString().slice(0, 16);
};

const formData = ref({
  receipt_master_id: null,
  sale_master_id: null,
  account_head_id: null,
  account_payable_head_id: null,
  vat_payable_account_head_id: null,
  doctor_contact_profile_id: null,
  bill_number: null,
  service_specification: null,
  service_resource_id: null,
  resource_description_id: null,
  contact_profile_id: null,
  adjustment_amount: 0,
  total_return_amount: 0,
  discount_head_id: null,
  date: getCurrentDateTime(),
  service_end_date_time: null,
  service_serial_no: '',
  has_item_detail: false,
  account_details: [],
  cost_of_sales_price: '',
  cost_of_sales_account_head_id: null,
  description: 'OPD service bill',
  sale_type: 'opd_invoice',
  receipt_type: 'opd_invoice_return',
  status: 'active',

  payment_bill_number: null,
  mop_account_head_id: null,
  receipt_reference: '',
  payment: 0,
})

const account_details = ref({
  account_head_id: null,
  product_id: null,
  amount: '',
  vat_amount: 0,
  description: 'OPD service bill',
  taxable_amount: null,
  tax_rate: null,
  discount_amount: null,
  discount_percent: 0,
  total_amount: 0,
  business_id: null
})

const business = ref([])
const cashAndBank = ref([])
const accountReceivable = ref([])
const accountPayable = ref([])
const vatPayable = ref([])
const additionalOption = ref([])
const serviceResources = ref([])
const patient = ref({});
const selectedPatient = ref(null);
const doctors = reactive([]);
const homeContent = ref({
  resource_type: {},
  resource_specifications: {}
})

const companyId = computed(() => $route.params.companyId);
const specificationArr = computed(() => objToArray(homeContent.value.resource_specifications))
const { fetchServiceSerialNo} = handleVoucherNo()
const {fetchBusinessList} = handleCBusinesses()
const {fetchCashAndBank} = handleReceipt();
const {fetchAccountReceivable, fetchVatPayable, fetchAccountPayable, fetchSalesBill} = handlePurchase()
const {fetchHome} = handleInventory();
const {returnOPDBilling} = handleHospitalBilling();
const {fetchPatientDetails, fetchServiceResourceList, dateOfBarthConvertToAge} = handleHospital()

const onCreateProfile = async (profileInfo) => {
  selectedPatient.value = {
    id: profileInfo.id,
    name: profileInfo.full_name
  };
  additionalOption.value.push(selectedPatient.value)
  formData.value.contact_profile_id = profileInfo.id;
  await onSelectPatient(profileInfo);
}

const onSelectServiceResource = async (selectedServiceResource) => {
  formData.value.doctor_contact_profile_id = selectedServiceResource.contact_profile_id
  let res = await fetchServiceSerialNo(`?company_id=${companyId.value}&voucher_type=service_sl_no&service_resource_id=${formData.value.service_resource_id}&date=${formData.value.date}`);
  formData.value.service_serial_no = res.data
}

const onSelectPatient = async (profileInfo) => {
  const companyQuery = `?company_id=${companyId.value}`
  await fetchPatientDetails(companyQuery, profileInfo.id).then((res) => {
    if (!res.status) {
      return showError(res.message)
    }

    patient.value = res.data;

    if (patient.value.birthday) {
      const {year, month, day} = dateOfBarthConvertToAge(patient.value.birthday)
      patient.value.age = `${year}Y, ${month}M, ${day}D`
    }
  })
}

const onSelectVisitType = () => {
  account_details.value.amount = 0;
  formData.value.resource_description_id = account_details.value.account_head_id = account_details.value.account_head_id = formData.value.cost_of_sales_account_head_id = null
  formData.value.cost_of_sales_price = formData.value.service_end_date_time = '';

  const {service_resource_id, service_specification} = formData.value;
  if (!service_resource_id || !service_specification) {
    return
  }

  const findServiceResource = serviceResources.value.find((item) => item.id == service_resource_id)
  if (!findServiceResource) {
    return
  }

  const findServiceResourceDesc = findServiceResource.resource_descriptions.find((desc) => desc.service_specification == service_specification)
  if (!findServiceResourceDesc) {
    return
  }

  formData.value.resource_description_id = findServiceResourceDesc.id
  account_details.value.product_id = findServiceResourceDesc.product_id
  account_details.value.amount = findServiceResourceDesc.sales_price
  account_details.value.account_head_id = findServiceResourceDesc.sales_account_head_id

  formData.value.cost_of_sales_price = findServiceResourceDesc.cost_of_sales_price
  formData.value.cost_of_sales_account_head_id = findServiceResourceDesc.cost_of_sales_account_head_id

  const duration = parseInt(findServiceResourceDesc.service_duration)
  const startTime = new Date(formData.value.date);
  const endTime = new Date(startTime.getTime() + duration * 60000);
  formData.value.service_end_date_time = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
}

const setInitialValue = (array, field) => {
  if(!array.length) return;
  formData.value[field] = array[0].id
}

// Create a computed property to calculate netPayable
const netPayable = computed(() => {
  // Parse the input values as numbers (assuming they are numeric input fields)
  const fees = parseFloat(account_details.value.amount);
  const vat = parseFloat(account_details.value.vat_amount);
  const discount = parseFloat(formData.value.adjustment_amount);

  // Calculate the netPayable amount
  const net = fees + (vat ? vat : 0) - (discount ? discount : 0);

  // Ensure the result is a non-negative number
  const netPayableAmount = isNaN(net) || net < 0 ? 0 : net;
  formData.value.payment = netPayableAmount

  return netPayableAmount;
});

const saveOPDBilling = () => {
  loading.value = true
  const formattedData = formatData();
  returnOPDBilling(formattedData).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    showSuccess(res.message)
    fetchAndGeneratePdf(company.value, res.data.id, 'opd_invoice')
    closeButton()
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const formatData = () => {

  const formattedAccountDetails = structuredClone(account_details.value);
  formattedAccountDetails.total_amount = netPayable.value
  formattedAccountDetails.vat_amount = formattedAccountDetails.vat_amount ? formattedAccountDetails.vat_amount : 0;
  formattedAccountDetails.discount_amount = formattedAccountDetails.discount_amount ? formattedAccountDetails.discount_amount : 0;

  const formattedData = structuredClone(formData.value);
  formattedData.company_id = companyId.value
  formattedData.bill_number = invoicePrefix.value + '-' + invoiceSerial.value
  formattedData.payment_voucher_no = paymentPrefix.value + '-' + paymentSerial.value
  formattedData.account_details = [formattedAccountDetails];
  formattedData.vat_amount = formattedData.vat_amount ? formattedData.vat_amount : 0;
  formattedData.discount_amount = formattedData.discount_amount ? formattedData.discount_amount : 0;
  return formattedData;
}

watch(selectedPatient, (newValue) => {
  if (newValue) {
    formData.value.contact_profile_id = newValue.id;
    onSelectPatient(newValue)
  }
})

onMounted(async () => {
  loading.value = true
  const companyQuery = `?company_id=${companyId.value}`
  const salesBillQuery = `${companyQuery}&return_data=1`

  const homeRes = fetchHome();
  const businessRes = fetchBusinessList(companyQuery)
  const serviceResourceRes = fetchServiceResourceList(companyQuery);
  const cashAndBankRes = fetchCashAndBank(companyId.value);
  const receivableRes = fetchAccountReceivable(companyQuery)
  const payableRes = fetchAccountPayable(companyQuery)
  const vatPayableRes = fetchVatPayable(companyQuery)
  const salesAccountHeadsRes = getAccountHeadBySlag("sales_accounts", companyQuery)
  const getSalesBill = fetchSalesBill($route.params.invoiceId , salesBillQuery)

  await Promise.all([
    homeRes.then(({data}) => {
      if (data) {
        const {resource_type, resource_specifications} = data;
        homeContent.value.resource_type = resource_type;
        homeContent.value.resource_specifications = resource_specifications;
      }
    }),
    fetchContactProfiles(companyQuery + `&type=doctor`).then((res) => {
        if(res.status) doctors.push(...res.data)
    }),
    businessRes.then(({data}) => {
      if (data) business.value = data
    }),
    serviceResourceRes.then(({data}) => {
      if (data) serviceResources.value = data
    }),
    cashAndBankRes.then(({data}) => {
      if (data) cashAndBank.value = data
    }),
    receivableRes.then(({data}) => {
      if (data) accountReceivable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.account_head_id = data[0].id
    }),
    payableRes.then(({data}) => {
      if (data) accountPayable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.account_payable_head_id = data[0].id
    }),
    vatPayableRes.then(({data}) => {
      if (data) vatPayable.value = data
      if (data.length < 1) {
        return formData.value.account_head_id = null;
      }

      formData.value.vat_payable_account_head_id = data[0].id
    }),
    fetchCompanyInfo(companyId.value).then((res) => {
      company.value = res.data
    }),
    salesAccountHeadsRes.then(res => {
      if(res.data) salesAccountHeads.value = res.data
    }),
    getSalesBill.then(res => {
      if (res.data) {
        formData.value.sale_master_id = res.data.id;
        formData.value.service_resource_id = res.data.service_general.service_resource_id;
        formData.value.bill_number = res.data.bill_number;
        formData.value.adjustment_amount = res.data.total_discount;
        formData.value.resource_description_id = null;
        formData.value.invoice_date = res.data.service_general.start_date_time;
        formData.value.service_end_date_time = res.data.service_general.end_date_time;
        formData.value.service_serial_no = res.data.service_general.serial_no;
        formData.value.service_specification = res.data.service_general.service_specification;
        formData.value.has_item_detail = false;
        formData.value.account_details = [];
        formData.value.description = 'OPD service bill';
        formData.value.status = 'active';
        formData.value.payment_status = 'paid';

        if (res.data.cost_of_sales) {
          formData.value.cost_of_sales_price = res.data.cost_of_sales.debit;
          formData.value.cost_of_sales_account_head_id = res.data.cost_of_sales.account_head_id;
          formData.value.doctor_contact_profile_id = res.data.cost_of_sales.contact_profile_id;
        }

        formData.value.discount_head_id = res.data.discount_ledger ? res.data.discount_ledger.account_head_id : null;

        formData.value.payment_bill_number = res.data.receipt_generals[0].receipt_master.voucher_no;
        formData.value.receipt_master_id = res.data.receipt_generals[0].receipt_master.id;
        formData.value.receipt_reference = res.data.receipt_generals[0].receipt_master.receipt_reference;
        formData.value.paid_amount = res.data.receipt_generals[0].receipt_master.total_paid_amount;
        formData.value.total_return_amount = res.data.paid_amount


        account_details.value = {
          account_head_id: res.data.general[0].account_head_id,
          amount: res.data.general[0].amount,
          vat_amount: res.data.general[0].vat_amount,
          description: res.data.general[0].description,
          taxable_amount: res.data.general[0].taxable_amount ?? 0,
          tax_rate: res.data.general[0].tax_rate ?? 0,
          discount_amount: res.data.general[0].discount_amount,
          discount_percent: res.data.general[0].discount_percent,
          total_amount: res.data.general[0].total_amount,
          sale_general_id: res.data.general[0].id,
        }
        account_details.value.business_id = res.data.business_id;

        patient.value = {};
        selectedPatient.value = {
          id: res.data.contact.id,
          name: res.data.contact.name
        };

        let invoiceVoucher = editVoucherNumber(res.data.bill_number);
        invoicePrefix.value = invoiceVoucher.prefix;
        invoiceSerial.value = invoiceVoucher.serial;

        let paymentVoucher = editVoucherNumber(res.data.receipt_generals[0].receipt_master.voucher_no);
        paymentPrefix.value = paymentVoucher.prefix;
        paymentSerial.value = paymentVoucher.serial;
      }
    })
  ]).then(() => {
    setInitialValue(business.value, 'business_id');
    setInitialValue(cashAndBank.value, 'mop_account_head_id');
    loading.value = false;
  }).catch((err) => {
    loading.value = false
  });
})
</script>

<style scoped>
.mr-3 {
  margin-right: 3px;
}

.mbt-20 {
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
